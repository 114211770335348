/**
 * Retrieve the localisations for a given entry.
 *
 * @param {MaybeRefOrGetter<any>} entry - The entry for which to retrieve the localisations.
 * @return {{localisations: ComputedRef<unknown>}} - An object containing the localisations for the entry in the current multisite group.
 *   - localisations: An array of localisations that belong to the current multisite group.
 */
export const useLocalisations = (entry) => {
    const { sitesInGroup } = useMultisite();
    const { locales } = useI18n();

    const code = (iso) => {
        return locales.value?.find((locale) => locale.iso === iso || locale.code === iso)?.code;
    };

    const localisations = computed(() => {
        return toValue(entry)?.entry?.localized ?? [];
    });

    const fixedLocalisations = computed(() => {
        return localisations.value.map(({ siteId, uri, language }) => {
            return {
                siteId,
                uri,
                code: code(language),
                language
            };
        });
    });

    const localisationsInGroup = computed(() => {
        return fixedLocalisations.value?.filter(({ siteId }) => {
            return sitesInGroup.value?.includes(siteId) ?? false;
        }) ?? [];
    });

    return {
        localisations: localisationsInGroup,
    };
};
